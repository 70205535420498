const specialtyConverter = (specialty) => {
	switch (specialty) {
		case 'Visiting Visas':
			return 'Visiting_Visas'
		case 'Family Visas':
			return 'Family_Visas'
		case 'Skilled Visas':
			return 'Skilled_Visas'
		case 'Employer Visas':
			return 'Employer_Visas'
		case 'Business Visas':
			return 'Business_Visas'
		case 'Tourist Visas':
			return 'Tourist_Visas'
		case 'Other Personal Visas':
			return 'Other_Personal_Visas'
		case 'Humanitarian Visas':
			return 'Humanitarian_Visas'
		case 'Bridging Visas':
			return 'Bridging_Visas'
		case 'Student Visas':
			return 'Student_Visas'
		default:
			break
	}
}

const reverseSpecialtyConverter = (specialty) => {
	switch (specialty) {
		case 'Visiting_Visas':
			return 'Visiting Visas'
		case 'Family_Visas':
			return 'Family Visas'
		case 'Skilled_Visas':
			return 'Skilled Visas'
		case 'Employer_Visas':
			return 'Employer Visas'
		case 'Business_Visas':
			return 'Business Visas'
		case 'Tourist_Visas':
			return 'Tourist Visas'
		case 'Other_Personal_Visas':
			return 'Other Personal Visas'
		case 'Humanitarian_Visas':
			return 'Humanitarian Visas'
		case 'Bridging_Visas':
			return 'Bridging Visas'
		case 'Student_Visas':
			return 'Student Visas'
		default:
			break
	}
}

const categoryConverter = (category) => {
	switch (category) {
		case 'Visiting Visas':
			return 'Visiting_Visas'
		case 'Family Visas':
			return 'Family_Visas'
		case 'Skilled Visas':
			return 'Skilled_Visas'
		case 'Employer Visas':
			return 'Employer_Visas'
		case 'Business Visas':
			return 'Business_Visas'
		case 'Tourist Visas':
			return 'Tourist_Visas'
		case 'Other Personal Visas':
			return 'Other_Personal_Visas'
		case 'Humanitarian Visas':
			return 'Humanitarian_Visas'
		case 'Bridging Visas':
			return 'Bridging_Visas'
		case 'Student Visas':
			return 'Student_Visas'
		default:
			return category
	}
}

const reverseCategoryConverter = (category) => {
	switch (category) {
		case 'Visiting_Visas':
			return 'Visiting Visas'
		case 'Family_Visas':
			return 'Family Visas'
		case 'Skilled_Visas':
			return 'Skilled Visas'
		case 'Employer_Visas':
			return 'Employer Visas'
		case 'Business_Visas':
			return 'Business Visas'
		case 'Tourist_Visas':
			return 'Tourist Visas'
		case 'Other_Personal_Visas':
			return 'Other Personal Visas'
		case 'Humanitarian_Visas':
			return 'Humanitarian Visas'
		case 'Bridging_Visas':
			return 'Bridging Visas'
		case 'Student_Visas':
			return 'Student Visas'
		default:
			return category
	}
}

export { specialtyConverter, reverseSpecialtyConverter, categoryConverter, reverseCategoryConverter }
