import { OutlinedInput, styled } from '@mui/material'

const SelectMenuProps = {
    PaperProps: {
        style: {
            fontFamily: "'Space Grotesk', sans-serif",
            border: '2px solid #2F68FA',
            borderTop: 'none',
            borderTopLeftRadius: '0',
            borderTopRightRadius: '0',
        },
    },
}

const TimePickerMenuProps = {
    PaperProps: {
        style: {
            maxHeight: '150px',
            fontFamily: "'Space Grotesk', sans-serif",
            border: '2px solid #2F68FA',
            borderTop: 'none',
            borderTopLeftRadius: '0',
            borderTopRightRadius: '0',
        },
    },
}

export { 
    SelectMenuProps,
    TimePickerMenuProps,
}