import { FormControl, InputLabel, styled } from '@mui/material'

const TFAFormControl = styled(FormControl)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    fontFamily: "'Space Grotesk', sans-serif",
    '& fieldset': {
        zIndex: '0 !important',
        borderRadius: '6px',
        borderColor: '#2F68FA',
        borderWidth: '2px'
      },
      '&:hover fieldset': {
        borderColor: '#2F68FA',
        borderWidth: '2px',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#2F68FA',
        borderWidth: '2px',
      },
    },
}))


const TFAInputLabel = styled(InputLabel)(({ theme }) => ({
  fontFamily: '"Space Grotesk", sans-serif',
  color: '#022873',
  fontWeight: 'bold',
  fontSize: '1.1em',
  '&.Mui-focused': {
    color: '#022873'
  }
}))
export { TFAFormControl, TFAInputLabel }